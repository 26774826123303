import React from 'react'
import { Select } from '@r1/ui-kit'

export const SelectWidget = ({ options, value, onChange, rawErrors, hideError }) => {
  const hasError = rawErrors && rawErrors.length > 0 && !hideError
  const handleChange = val => {
    onChange(val ?? undefined)
  }

  return (
    <Select
      simpleValue
      error={hasError}
      valueKey="value"
      labelKey="label"
      value={value}
      options={options.enumOptions}
      onChange={handleChange}
    />
  )
}
